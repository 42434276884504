import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
// import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { jwtDecode } from 'jwt-decode';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  box: <Iconify icon="fluent:box-24-filled" sx={{ width: 24, height: 24 }} />,
  branch: <Iconify icon="fluent:branch-24-filled" sx={{ width: 24, height: 24 }} />,
  pallet: <Iconify icon="material-symbols:pallet-outline" sx={{ width: 24, height: 24 }} />,
  shelf: <Iconify icon="material-symbols:shelf-position-sharp" sx={{ width: 24, height: 24 }} />,
  check: <Iconify icon="pajamas:status-closed" sx={{ width: 24, height: 24 }} />,
  status: <Iconify icon="pajamas:status-preparing-borderless" sx={{ width: 24, height: 24 }} />,
  pick: <Iconify icon="tdesign:undertake" sx={{ width: 24, height: 24 }} />,
  boxToPallet: <Iconify icon="openmoji:move" sx={{ width: 24, height: 24 }} />,
  addToShelf: <Iconify icon="material-symbols:orders-outline" sx={{ width: 24, height: 24 }} />,
  customer: <Iconify icon="material-symbols:person" sx={{ width: 24, height: 24 }} />,
  airwayBill: <Iconify icon="jam:plane" sx={{ width: 24, height: 24 }} />,
  address: <Iconify icon="ic:baseline-place" sx={{ width: 24, height: 24 }} />,
  rs: <Iconify icon="mingcute:government-fill" sx={{ width: 24, height: 24 }} />,
  price: <Iconify icon="solar:tag-price-bold" sx={{ width: 24, height: 24 }} />,
  payment: <Iconify icon="fluent:payment-16-filled" sx={{ width: 24, height: 24 }} />,
  liveOrders: <Iconify icon="mingcute:live-fill" sx={{ width: 24, height: 24 }} />,
  customOrders: <Iconify icon="fluent-mdl2:custom-list" sx={{ width: 24, height: 24 }} />,
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const auth = JSON.parse(localStorage.getItem('token'));
  const managementItems = useMemo(() => {
    const matchingItems = [
      // customer
      {
        title: t('customer'),
        path: paths.dashboard.customer.root,
        icon: ICONS.customer,
      },

      // Orders
      {
        title: t('orders'),
        path: paths.dashboard.orders.root,
        icon: ICONS.order,
      },

      // Box To Pallet
      {
        title: t('Box To Pallet'),
        path: paths.dashboard.kanban.root,
        icon: ICONS.boxToPallet,
      },

      // Order Status
      {
        title: t('Order Status'),
        path: paths.dashboard.orderStatus.root,
        icon: ICONS.status,
      },

      // Box
      {
        title: t('Box'),
        path: paths.dashboard.box.root,
        icon: ICONS.box,
      },

      // Pallet
      {
        title: t('Pallet'),
        path: paths.dashboard.pallete.root,
        icon: ICONS.pallet,
      },

      // airwayBill
      {
        title: t('Air Waybill'),
        path: paths.dashboard.airwayBill.root,
        icon: ICONS.airwayBill,
      },
    ];
    let items = [];
    if (auth) {
      const decoded = jwtDecode(auth);
      if (decoded.location === 'USA') {
        items = [...matchingItems];
      }
      if (decoded.location === 'Georgia') {
        items = [
          ...matchingItems,
          // Branch
          {
            title: t('Branch'),
            path: paths.dashboard.branch.root,
            icon: ICONS.branch,
          },
          // Country
          {
            title: t('Country'),
            path: paths.dashboard.country.root,
            icon: ICONS.banking,
          },
          // shelf
          {
            title: t('shelf'),
            path: paths.dashboard.shelf.root,
            icon: ICONS.shelf,
          },
          // Add To Shelf
          {
            title: t('Add To Shelf'),
            path: paths.dashboard.warehouseLocation.root,
            icon: ICONS.addToShelf,
          },
          // Pick UP
          {
            title: t('Pick UP'),
            path: paths.dashboard.TimeSlot.root,
            icon: ICONS.pick,
          },
          // Check
          {
            title: t('Check'),
            path: paths.dashboard.OrderCheck.root,
            icon: ICONS.check,
          },
          // liveOrders
          {
            title: t('Live Orders'),
            path: paths.dashboard.liveOrders.root,
            icon: ICONS.liveOrders,
          },
          // Address
          {
            title: t('Address'),
            path: paths.dashboard.address.root,
            icon: ICONS.address,
          },
          // RS
          {
            title: t('RS'),
            path: paths.dashboard.rs.root,
            icon: ICONS.rs,
          },
          // customOrders
          {
            title: t('Custom'),
            path: paths.dashboard.customOrders.root,
            icon: ICONS.customOrders,
          },
          // Prices
          {
            title: t('Price'),
            path: paths.dashboard.price.root,
            icon: ICONS.price,
          },

          // payments
          {
            title: t('Payment'),
            path: paths.dashboard.payment.root,
            icon: ICONS.payment,
          },
        ];
      }
      if (decoded.location === 'China') {
        items = [...managementItems];
      }
    }

    return items;
  }, [t, auth]);

  const data = useMemo(
    () => [
      // MANAGEMENT
      {
        subheader: t(''),
        items: managementItems,
      },
    ],
    [t, managementItems]
  );

  return data;
}
